import React from 'react'
import { graphql } from 'gatsby';
import { Row, Col } from 'react-grid-system';
import _get from 'lodash/get';
import styled from 'styled-components';

import { useLocalize } from 'utils/localize';
import PageLayout from 'components/Layout/PageLayout';
import ContentBox from 'widgets/ContentBox';

const S = {};
S.ThankYou = styled.div`
  line-height: 2;
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
`;

const ThankYou = ({ data: rawData }) => {
  const data = useLocalize(rawData);

  const thankYou = _get(data, 'sanitySiteConfig._rawThankYouPage');

  return (
    <PageLayout>
      <Row align='center' justify='center'>
        <Col xs={8} component={S.ThankYou}>
          <ContentBox blocks={thankYou} />
        </Col>
      </Row>
    </PageLayout>
  )
}

export const query = graphql`
    query ReadThankYouMessage($language: String!) {
      sanitySiteConfig {
        _rawThankYouPage
      }
      locales: allLocale(filter: { language: {eq: $language} }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`

export default ThankYou;
